import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

// Components
import TopbarMenu from "./components/TopbarMenu";
import CartDrawer from "../CartDrawer";
import MobileMenu from "./components/MobileMenu";

// Scoped CSS
import styles from "./Topbar.module.scss";

// Images
import logo from "../../assets/images/logo.png";

// Context
import { useGlobalState } from "../../context/GlobalStateProvider";

// Icons
import {
  // SearchOutlined,
  ShoppingOutlined,
  UserOutlined,
  MenuOutlined,
} from "@ant-design/icons";

const topbarMenu = [
  {
    title: "Shop",
    value: "shop",
    children: [
      {
        name: (
          <div className={`${styles.links} p-15 cursor-pointer`}>
            MCT <span className="fw-300">PRO</span>ESSENTIALS
          </div>
        ),
        link: "/mct/proessentials",
      },
    ],
  },
  {
    title: "Why Monolith Nutrition?",
    value: "why",
    children: [
      {
        name: (
          <div className={`${styles.links} p-15 cursor-pointer`}>
            Why Monolith Nutrition?
          </div>
        ),
        link: "/about/why-monolith-nutrition",
      },
      {
        name: (
          <div
            className={`${styles.links} flex gap-10 a-i-center p-15 links cursor-pointer`}
          >
            <div>Absolutely MCT For Today And Tomorrow</div>
            {/* <DownOutlined /> */}
          </div>
        ),
        link: "/about/why-mct",
        // grandchildren: [
        //   {
        //     name: (
        //       <li className={`${styles.links} p-15 cursor-pointer`}>
        //         MCT ESSENTIALS
        //       </li>
        //     ),
        //     link: "/mct/essentials",
        //   },
        //   {
        //     name: (
        //       <li className={`${styles.links} p-15 cursor-pointer`}>
        //         MCT <span className="fw-300">LEAN</span>ESSENTIALS
        //       </li>
        //     ),
        //     link: "/mct/leanEssentials",
        //   },
        // ],
      },
    ],
  },
  {
    title: "Live Life & Learn",
    value: "learn",
    children: [
      {
        name: (
          <div className={`${styles.links} p-15 cursor-pointer`}>
            COMMUNITY BLOG
          </div>
        ),
        link: "/community-blog",
      },
    ],
  },
  {
    title: "Get In Touch!",
    value: "contact",
    children: [
      {
        name: (
          <div className={`${styles.links} p-15 cursor-pointer`}>
            Become a lifestyle affiliate
          </div>
        ),
        link: "/lifestyle-affiliate",
      },
      {
        name: (
          <div className={`${styles.links} p-15 cursor-pointer`}>
            Contact Us
          </div>
        ),
        link: "/contact",
      },
    ],
  },
];

function Topbar({ isScrollingUp, openCart, setOpenCart }) {
  const [openMenu, setOpenMenu] = useState(null);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const { isMobile, isLoggedIn } = useGlobalState();

  const userData = useSelector((state) => state.global.userData);

  const cartQty = userData?.cart?.cart_items?.reduce(
    (acc, cur) => acc + cur.qty,
    0
  );

  const handleOpenMenu = (val) => {
    if (openMenu === val) setOpenMenu(null);
    else setOpenMenu(val);
  };

  const handleOpenSubMenu = (e) => {
    e.stopPropagation();
    setOpenSubMenu(!openSubMenu);
  };

  const handleOpenCart = () => {
    setOpenCart(true);
  };

  const handleCloseCart = () => {
    setOpenCart(false);
  };

  const handleOpenMobileMenu = () => {
    setOpenMobileMenu(true);
  };

  const handleCloseMobileMenu = () => {
    setOpenMobileMenu(false);
  };

  useEffect(() => {
    if (!isMobile) setOpenMobileMenu(false);
  }, [isMobile]);

  return (
    <>
      <div
        className={`${styles.topbar} p-y-20 ${isScrollingUp ? "sticky" : ""}`}
      >
        <div className={`${styles.topbarContainer} container`}>
          <div className="logo flex a-i-center gap-20">
            {isMobile && <MenuOutlined onClick={handleOpenMobileMenu} />}
            <Link to="/">
              <img className={styles.logo} src={logo} alt="logo" />
            </Link>
          </div>

          <div className={`${styles.topbarMenu} text-uppercase fs-14`}>
            {topbarMenu.map((el, i) => (
              <TopbarMenu
                element={el}
                onOpenMenu={openMenu}
                onHandleOpenMenu={handleOpenMenu}
                onOpenSubMenu={openSubMenu}
                onHandleOpenSubMenu={handleOpenSubMenu}
                onSetOpenSubMenu={setOpenSubMenu}
                key={`topbarItem-${i}`}
              />
            ))}
          </div>

          <div className={styles.profileContainer}>
            {/* <SearchOutlined /> */}
            <Link to={`/${isLoggedIn() ? "account" : "login"}`}>
              <UserOutlined />
            </Link>
            <div className="p-relative cursor-pointer" onClick={handleOpenCart}>
              <ShoppingOutlined />
              {cartQty > 0 && <div className={styles.cartQty}>{cartQty}</div>}
            </div>
          </div>
        </div>
      </div>

      <CartDrawer onOpenCart={openCart} onHandleCloseCart={handleCloseCart} />
      <MobileMenu
        onOpenMobileMenu={openMobileMenu}
        onHandleCloseMobileMenu={handleCloseMobileMenu}
      />
    </>
  );
}

export default Topbar;
