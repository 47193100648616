import React from "react";
import { Link } from "react-router-dom";

// API
import { Divider } from "antd";

// Components
import ImgTxtTwoCol from "../components/common/ImgTxtTwoCol";

// Images
import proBoxImg from "../assets/images/pro/box_MCT ProEssentials_03.png";
import proSachetImg from "../assets/images/pro/sachet.png";
import fitnessYogaImg from "../assets/images/pro/fitness_yoga_01b.jpg";

import skewGutHealthImg from "../assets/images/pro/skew/gutHealth.jpg";
import skewWeightMgmtImg from "../assets/images/pro/skew/weightMgmt.jpg";
import skewCogHealthImg from "../assets/images/pro/skew/cognitiveHealth.png";
import skewCoconutImg from "../assets/images/pro/skew/coconut_01.jpg";
import sustainImg from "../assets/images/sustainability-01.jpg";

import skewKetonesImg from "../assets/images/pro/skew/ketones.jpg";

import wellnessHubImg from "../assets/images/pro/lg-360-Wellness-Hub.jpg";

import coconutCertImg from "../assets/images/pro/cert/100percent-coconut.png";
import zeroSugarImg from "../assets/images/pro/cert/sugar-free.png";
import glutenFreeImg from "../assets/images/pro/cert/gluten-free.png";
import vegFriendlyImg from "../assets/images/pro/cert/vegetarian-friendly.png";

const certImages = [
  { title: "100% coconut", img: coconutCertImg },
  { title: "zero sugar formulation", img: zeroSugarImg },
  { title: "gluten free", img: glutenFreeImg },
  { title: "vegetarian friendly", img: vegFriendlyImg },
];

const proBenefits = {
  sachetData: {
    title: (
      <div className="text-uppercase">
        EXPECT <span className="text-red">BIG THINGS</span> from every humble
        sachet
      </div>
    ),
    body: (
      <div>
        The benefits of MCT (Medium-chain triglycerides) as foundational
        nutrition stems from its natural ability to be rapidly absorbed and
        metabolised for its myriad of benefits that does the body good. This
        healthy fatty acid with its unique metabolic characteristics is often
        supplemented with medicine to treat food absorption disorders including
        diarrhea, steatorrhea and liver disease
        <sup>
          <small>1,2</small>
        </sup>
        . It all begins with one sachet at a time for{" "}
        <span className="text-red">good health</span> and{" "}
        <span className="text-red">better living</span>.
      </div>
    ),
    image: proSachetImg,
    imgDirection: "left",
    imgSpaceDirection: "m-r-auto",
  },
  gutHealth: {
    title: (
      <div>
        GREATLY IMPROVE AND MAINTAIN{" "}
        <span className="text-red">GUT HEALTH</span>
      </div>
    ),
    body: (
      <div>
        Widely accepted as a means to manage metabolic diseases through the
        promoting positive alteration of gut microbiota
        <sup>
          <small>3</small>
        </sup>
        , MCT ProEssentials works to improve the intestinal ecosystem and
        enhance metabolic health for a{" "}
        <span className="text-red">healthy and happy gut</span> that brings a
        host of benefits ranging from better disgestion, rest and recuperation,
        mental well-being, and immune health
        <sup>
          <small>4</small>
        </sup>
      </div>
    ),
    image: skewGutHealthImg,
    imgDirection: "right",
    imgSpaceDirection: "m-l-auto",
  },
  ketones: {
    title: (
      <div>
        AID IN INDUCING STATES OF <span className="text-red">KETOSIS</span>
      </div>
    ),
    body: (
      <div>
        Shift away from the common glucose metabolism to ketone metabolism.
        Support and{" "}
        <span className="text-red">promote natural ketone metabolism</span>
        <sup>
          <small>5</small>
        </sup>{" "}
        when introducing MCT ProEssentials with a healthy low carb, high in
        protein and fat from quality sources meal regimen and lifestyle as
        introduced by your doctor or qualified physician.
      </div>
    ),
    image: skewKetonesImg,
    imgDirection: "left",
    imgSpaceDirection: "m-r-auto",
  },
  cognitiveHealth: {
    title: (
      <div>
        FOUNDATIONAL BENEFITS FOR{" "}
        <span className="text-red">COGNITIVE HEALTH</span>
      </div>
    ),
    body: (
      <div>
        Use MCT ProEssentials to exploit the benefits of ketone metabolism for
        its natural <span className="text-red">neuroprotective</span>{" "}
        properties6 to treat and improvement various neurological conditions,
        ultimately aiding in improving working memory and overall cognitive
        function
        <sup>
          <small>7</small>
        </sup>
        .
      </div>
    ),
    image: skewCogHealthImg,
    imgDirection: "right",
    imgSpaceDirection: "m-l-auto",
  },
  weightMgmt: {
    title: (
      <div>
        <span className="text-red">EFFICIENT</span> WEIGHT MANAGEMENT
      </div>
    ),
    body: (
      <div>
        Whether taken alone or with other supplements, experience improved
        weight management and weight loss
        <sup>
          <small>8</small>
        </sup>{" "}
        through natural <span className="text-red">thermogenesis</span>
        <sup>
          <small>9</small>
        </sup>
        . Leverage on MCT’s inherent physiological effects which increase bodily
        energy expenditure, aiding satiety, and improving metabolism
        <sup>
          <small>10</small>
        </sup>
        .
      </div>
    ),
    image: skewWeightMgmtImg,
    imgDirection: "left",
    imgSpaceDirection: "m-r-auto",
  },
  energyMgmt: {
    title: (
      <div>
        SPUR METABOLIC CHANGE THROUGH <span className="text-red">ENERGY</span>{" "}
        MANAGEMENT
      </div>
    ),
    body: (
      <div>
        Jumpstart your day right with MCT ProEssentials inherently acting as a
        significant catalyst for a constant energy-supplying fuel
        <sup>
          <small>11</small>
        </sup>{" "}
        for improved energy levels. Experience MCT-induced mitochondrial
        biogenesis and metabolism – an added precursor for better exercise
        performance
        <sup>
          <small>12</small>
        </sup>
        .
      </div>
    ),
    image: skewCogHealthImg,
    imgDirection: "right",
    imgSpaceDirection: "m-l-auto",
  },
  coconut: {
    title: (
      <div>
        <span className="text-red">EASY-MIX</span> MCT POWDER FROM{" "}
        <span className="text-red">100% ORGANIC COCONUTS</span>
      </div>
    ),
    body: (
      <div>
        Enjoy and experience quality MCT in powder form, formulated for ease of
        mixability and ease of consumption at recommended doses.
        <br />
        <br />
        MCT ProEssentials is derived from{" "}
        <span className="text-red">100% organic coconuts</span> ,which are
        sourced from plantations with sustainable farming methods and practices
        which include the preservation of ecological structures such as
        biodiversity, workers welfare, soil and water conservation, and crop
        rotation.
      </div>
    ),
    image: skewCoconutImg,
    imgDirection: "left",
    imgSpaceDirection: "m-r-auto",
  },
};

const sorucesList = [
  "1. P G, Roopashree & Shetty, Shilpa & Kumari, Suchetha. (2021). Effect of medium chain fatty acid in human health and disease. Journal of Functional Foods. 87. 104724. 10.1016/j.jff.2021.104724.",
  "2. Wang, Y., Liu, Z., Han, Y., Xu, J., Huang, W., & Li, Z. (2018). Medium Chain Triglycerides enhances exercise endurance through the increased mitochondrial biogenesis and metabolism. PloS one, 13(2), e0191182. https://doi.org/10.1371/journal.pone.0191182",
  "3. Rial, S. A., Karelis, A. D., Bergeron, K. F., & Mounier, C. (2016). Gut Microbiota and Metabolic Health: The Potential Beneficial Effects of a Medium Chain Triglyceride Diet in Obese Individuals. Nutrients, 8(5), 281. https://doi.org/10.3390/nu8050281",
  "4. Wu, H. J., & Wu, E. (2012). The role of gut microbiota in immune homeostasis and autoimmunity. Gut microbes, 3(1), 4–14. https://doi.org/10.4161/gmic.19320",
  "5. Lin, T. Y., Liu, H. W., & Hung, T. M. (2021). The Ketogenic Effect of Medium-Chain Triacylglycerides. Frontiers in nutrition, 8, 747284. https://doi.org/10.3389/fnut.2021.747284",
  "6. Shcherbakova, K., Schwarz, A., Ivleva, I., Nikitina, V., Krytskaya, D., Apryatin, S., Karpenko, M., & Trofimov, A. (2023). Short- and long-term cognitive and metabolic effects of medium-chain triglyceride supplementation in rats. Heliyon, 9(2), e13446. https://doi.org/10.1016/j.heliyon.2023.e13446",
  "7. Rebello, C. J., Keller, J. N., Liu, A. G., Johnson, W. D., & Greenway, F. L. (2015). Pilot feasibility and safety study examining the effect of medium chain triglyceride supplementation in subjects with mild cognitive impairment: A randomized controlled trial. BBA clinical, 3, 123–125. https://doi.org/10.1016/j.bbacli.2015.01.001",
  "8. St-Onge, M. P., Ross, R., Parsons, W. D., & Jones, P. J. (2003). Medium-chain triglycerides increase energy expenditure and decrease adiposity in overweight men. Obesity research, 11(3), 395–402. https://doi.org/10.1038/oby.2003.53",
  "9. Hill, J. O., Peters, J. C., Yang, D., Sharp, T., Kaler, M., Abumrad, N. N., & Greene, H. L. (1989). Thermogenesis in humans during overfeeding with medium-chain triglycerides. Metabolism: clinical and experimental, 38(7), 641–648. https://doi.org/10.1016/0026-0495(89)90101-7",
  "10. St-Onge, M. P., & Jones, P. J. (2002). Physiological effects of medium-chain triglycerides: potential agents in the prevention of obesity. The Journal of nutrition, 132(3), 329–332. https://doi.org/10.1093/jn/132.3.329",
  "11. Van Zyl, C. G., Lambert, E. V., Hawley, J. A., Noakes, T. D., & Dennis, S. C. (1996). Effects of medium-chain triglyceride ingestion on fuel metabolism and cycling performance. Journal of applied physiology (Bethesda, Md. : 1985), 80(6), 2217–2225. https://doi.org/10.1152/jappl.1996.80.6.2217",
  "12. Wang, Y., Liu, Z., Han, Y., Xu, J., Huang, W., & Li, Z. (2018). Medium Chain Triglycerides enhances exercise endurance through the increased mitochondrial biogenesis and metabolism. PloS one, 13(2), e0191182. https://doi.org/10.1371/journal.pone.0191182",
];

function MonolithPro() {
  return (
    <div className="proPage">
      <div className="container">
        <div className="banner m-y-50">
          <div className="flex flex-col gap-20 half-width">
            <div className="big-title text-uppercase">
              A <span className="text-red">Purpose-Driven</span> Approach To
              Functional Nutrition
            </div>
            <div>
              Support your goals for good health and better living with optimum
              and superior MCT supplementation.
            </div>
          </div>
          <img className="half-width" src={proBoxImg} alt="MCT PRO" />
        </div>

        <Divider />

        <div className="m-y-50">
          <ImgTxtTwoCol data={proBenefits.sachetData} />
        </div>
      </div>

      <div className="yogaImgContainer">
        <img className="yogaImg" src={fitnessYogaImg} alt="fitness yoga" />
        <div className="imgBackdrop"></div>
      </div>

      <div className={`skewFromRight bgBlack p-relative text-white skewRow`}>
        <div className="blackSkewBg">
          <div className="container">
            <ImgTxtTwoCol data={proBenefits.gutHealth} />
          </div>
        </div>
      </div>

      <div className={`skewFromLeft bgBlack p-relative text-center skewRow`}>
        <div className="whiteSkewBg">
          <div className="container">
            <ImgTxtTwoCol data={proBenefits.ketones} />
          </div>
        </div>
      </div>

      <div className={`skewFromRight bgBlack p-relative text-white skewRow`}>
        <div className="blackSkewBg">
          <div className="container">
            <ImgTxtTwoCol data={proBenefits.cognitiveHealth} />
          </div>
        </div>
      </div>

      <div className={`skewFromLeft bgBlack p-relative skewRow`}>
        <div className="whiteSkewBg">
          <div className="container">
            <ImgTxtTwoCol data={proBenefits.weightMgmt} />
          </div>
        </div>
      </div>

      <div className={`skewFromRight bgBlack p-relative text-white skewRow`}>
        <div className="blackSkewBg">
          <div className="container">
            <ImgTxtTwoCol data={proBenefits.energyMgmt} />
          </div>
        </div>
      </div>

      <div
        className={`skewFromLeft bgBlack p-relative text-center skewRow coconutSkew`}
        style={{
          backgroundImage: `url("${skewCoconutImg}")`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "left top",
          backgroundSize: "contain",
          zIndex: "-2",
        }}
      >
        <div className="container">
          <div className="coconutText">
            <div className="text-left fs-35 font-bold m-b-20">
              <span className="text-red">EASY-MIX</span> MCT POWDER FROM{" "}
              <span className="text-red">100% ORGANIC COCONUTS</span>
            </div>

            <div className="text-left">
              Enjoy and experience quality MCT in powder form, formulated for
              ease of mixability and ease of consumption at recommended doses.
              <br />
              <br />
              MCT ProEssentials is derived from{" "}
              <span className="text-red">100% organic coconuts</span> ,which are
              sourced from plantations with sustainable farming methods and
              practices which include the preservation of ecological structures
              such as biodiversity, workers welfare, soil and water
              conservation, and crop rotation.
            </div>
          </div>
        </div>
      </div>

      <div className="placeOrderSection">
        <div className="container">
          <div className="text-center p-y-50">
            <Link
              to="/mct/proessentials"
              className="btn btn-primary btn-large w-400 fs-25 h-80 text-uppercase"
            >
              Place Your Order
            </Link>
          </div>
        </div>
      </div>

      <div className="exclusiveSection">
        <div className="container">
          <div className="flex flex-col gap-20 j-c-center a-i-center text-center">
            <div className="text-uppercase">Exclusively Distributed By</div>
            <img
              style={{ width: "250px", margin: "auto" }}
              src={wellnessHubImg}
              alt="360 wellness hub"
            />

            <div className="wellnessHubInfo m-b-80">
              <div>360 Wellness Hub</div>
              <div>
                Wisma Lifecare
                <br />
                Bangsar South, 2-2B 2fl., Wisma Lifecare,
                <br />
                5 Jalan Kerinchi, Bangsar South,
                <br />
                59200 Kuala Lumpur, Malaysia
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="passionSection text-center m-b-50">
        <div className="container">
          Monolith Nutrition™ is wholeheartedly dedicated to a{" "}
          <span className="text-red">passionate</span> and{" "}
          <span className="text-red">purpose-driven</span> pursuit for good
          health and better living through the formulation of{" "}
          <span className="text-red">beneficial</span>,{" "}
          <span className="text-red">relevant</span>, and{" "}
          <span className="text-red">enduring</span> health and nutritional
          products. Manufactured to the highest international standards with
          all-natural ingredients and a proud{" "}
          <span className="text-red">commitment to sustainability</span> from
          product to packaging, use with utmost{" "}
          <span className="text-red">confidence</span>.
          <br />
          <br />
          Find our more at monolithnutrition.com
        </div>
      </div>

      <div className="certSection m-b-100">
        <div className="container">
          <div className="flex flex-wrap a-i-center j-c-center gap-20">
            {certImages.map((el) => (
              <img
                style={{ width: "250px" }}
                src={el.img}
                alt={el.title}
                key={el.title}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="sourcesSection">
        <div className="container">
          <div className="fs-20 text-uppercase m-b-30">Sources</div>
          {sorucesList.map((el, i) => (
            <div className="m-b-20 fw-300" key={`sourceList-${i}`}>
              {el}
            </div>
          ))}
        </div>
      </div>

      <div className="beachSection p-relative">
        <div
          className="beachImg"
          style={{ backgroundImage: `url("${sustainImg}")` }}
        ></div>
      </div>
    </div>
  );
}

export default MonolithPro;
