import React from "react";
import { Link } from "react-router-dom";
import { essentialProductId, leanProductId, proProductId } from "./constants";

// Images
import proEssentialBox1 from "../assets/images/pro/box_MCT ProEssentials_03.png";
import proEssentialBox2 from "../assets/images/pro/box_MCT ProEssentials_01.png";
import proEssentialBox3 from "../assets/images/pro/mockup_box_MCT ProEssentials_04.png";

export const isPro = "1";

export const productList = {
  proessentials: {
    id: proProductId,
    path: "proessentials",
    breadcrumbName: [
      { title: <Link to="/">Home</Link> },
      { title: <div>MCT Pro Essentials</div> },
    ],
    productName: (
      <div className="big-title m-b-5">
        MCT <span className="fw-300">PRO</span>ESSENTIALS
      </div>
    ),
    nameString: "MCT PROESSENTIALS",
    productDescHead:
      "High protein ready to drink nutritional shake with 100% coconut MCT and a prebiotics matrix.",
    productDescBody:
      "Monolith Nutrition MCT (medium-chain triglycerides) LEANESSENTIALS is packed with whey protein concentrate (WPC80) goodness. Each box contains 15 convenient ready to drink lifestyle sachets power-packed with all the benefits of MCT and a superior protein source from whey.",
    price: 894,
    size: "60 / 3 boxes",
    flavour: "Original",
    productImages: [proEssentialBox1, proEssentialBox2, proEssentialBox3],
    // productFeatureImages: [
    //   leanFeature1Img,
    //   leanFeature2Img,
    //   leanFeature3Img,
    //   leanFeature4Img,
    //   leanFeature5Img,
    //   leanFeature6Img,
    // ],
    accordionData: [
      {
        title: "Recommended Use",
        content: (
          <div className="recommendAccContent">
            <div className="accordionGridContent">
              <div className="paragraph title">Serving Size</div>
              <div className="paragraph content">5g</div>
              <div className="paragraph title">Servings Per Box</div>
              <div className="paragraph content">20 sachets</div>
              {/* <div className="paragraph title">Serving Suggestions</div>
              <div className="paragraph content">
                Take 1 – 2 sachets a day. Best consumed before breakfast or
                between meals. Enjoy a nutritious and great tasting shake by
                mixing a sachet with 250 – 300ml of water, milk, tea or coffee.
                Designed for superior taste and mixability. Best enjoyed cold
                and chilled. Store product in a cool, dry place.
                <br />
                <br />
                Enjoy a hearty and nutritious shake by mixing a sachet with 250
                – 300ml of water, milk, tea or coffee. MCT LeanEssentials is
                designed to absolutely mix and taste great. Mix a sachet of MCT
                LeanEssentials into cold milk to enjoy with cereal, or blend
                into a delicious smoothie shake with your favourite fruits,
                oats, or nuts. The Chocolate Rapture flavour lends itself to
                adding a taste profile that rivals your favourite cocoa or
                coffee smoothie shake so go wild and be creative! Check out our
                community blog for fun (and more importantly, healthy) serving
                ideas!
              </div> */}
            </div>
          </div>
        ),
      },
      // {
      //   title: "Ingredients",
      //   content: (
      //     <div className="ingredientAccContent">
      //       <div className="paragraph m-b-30">
      //         Whey Protein Concentrate, Cocoa Powder, Non-dairy Creamer, Sugar,
      //         Proprietary Coconut Dietary Fibre, Prebiotics
      //         (Fructooligosaccharide), Coconut MCT (Medium-chain triglycerides),
      //         Acacia Fibre, Guar Gum (INS 412) as permitted stabilizer,
      //         Sucralose (INS 955) as permitted sweetener and permitted
      //         flavoring.
      //       </div>
      //       <div className="paragraph">
      //         All additives are of plant origin. Manufactured in a facility that
      //         processes dairy, eggs, soy, and nuts.
      //       </div>
      //     </div>
      //   ),
      // },
      // {
      //   title: "Supplement Facts",
      //   content: (
      //     <div className="supplementAccContent">
      //       <div className="paragraph m-b-10">Serving Size: 1 sachet (5g)</div>
      //       <div className="paragraph m-b-10">Amount Per Serving</div>
      //       <div className="accordionGridContent">
      //         <div className="paragraph title">Total Fat</div>
      //         <div className="paragraph content">3.5g</div>
      //         <div className="paragraph title p-l-10">Saturated Fat</div>
      //         <div className="paragraph content">2.5g</div>
      //         <div className="paragraph title p-l-10">Trans Fat</div>
      //         <div className="paragraph content">0g</div>
      //         <div className="paragraph title">Cholesterol</div>
      //         <div className="paragraph content">33mg</div>
      //         <div className="paragraph title">Sodium</div>
      //         <div className="paragraph content">132mg</div>
      //         <div className="paragraph title">Total Carbohydrates</div>
      //         <div className="paragraph content">17.6g</div>
      //         <div className="paragraph title p-l-10">Dietary Fiber</div>
      //         <div className="paragraph content">6g</div>
      //         <div className="paragraph title p-l-10">
      //           <div>Total Sugars</div>
      //           <div className="p-l-10">Includes 0.6 of Added Sugars</div>
      //         </div>
      //         <div className="paragraph content">8.9g</div>
      //         <div className="paragraph title">Protein</div>
      //         <div className="paragraph content">15g</div>
      //       </div>
      //     </div>
      //   ),
      // },
    ],
  },
};

export const BreakingPoint = {
  HDPLUS: "(min-width: 1500px)",
  IPADPRO: "(max-width: 1200px)",
  IPAD: "(max-width: 992px)",
};

export const statusList = [
  { label: "All", value: "" },
  { label: "Absent", value: "Absent" },
  { label: "On Leave", value: "On Leave" },
  { label: "Half Day", value: "Half Day" },
  { label: "On Duty", value: "On Duty" },
  { label: "Rest Day", value: "Rest Day" },
  { label: "Holiday", value: "Holiday" },
  { label: "Late Clock In", value: "Late Clock In" },
  { label: "Early Clock Out", value: "Early Clock Out" },
  { label: "Replacement Leave", value: "Replacement Leave" },
  { label: "Late Clock Out", value: "Late Clock Out" },
];

export const contractType = [
  { label: "All", value: "" },
  { label: "Permanent", value: "Permanent" },
  { label: "Contract", value: "Contract" },
  { label: "Probation", value: "Probation" },
];

export const etypeList = [
  { label: "Full-time", value: "Full-time" },
  { label: "Part-time", value: "Part-time" },
];

export const ctypeList = [
  { label: "Permanent", value: "Permanent" },
  { label: "Contract", value: "Contract" },
  { label: "Probation", value: "Probation" },
];
export const ctypeListLeave = [
  { label: "All", value: "All" },
  { label: "Permanent", value: "Permanent" },
  { label: "Contract", value: "Contract" },
  { label: "Probation", value: "Probation" },
];
export const staffCategory = [
  { label: "Non-Academic", value: "Non-Academic" },
  { label: "Academic", value: "Academic" },
];

export const identificationList = [
  { label: "IC", value: "IC " },
  { label: "Passport", value: "Passport " },
];

export const degreeList = [
  { label: "Foundation", value: "Foundation" },
  { label: "Diploma", value: "Diploma" },
  { label: "Degree", value: "Degree" },
  { label: "Masters Degree", value: "Masters Degree" },
  { label: "Doctorate", value: "Doctorate" },
];

export const positionList = [
  { label: "Junior Executive", value: "Junior Executive" },
  { label: "Senior Executive", value: "Senior Executive" },
  { label: "Assistant Manager", value: "Assistant Manager" },
  { label: "Manager", value: "Manager" },
  { label: "Senior Manager", value: "Senior Manager" },
  { label: "Junior Partner", value: "Junior Partner" },
  { label: "Senior Partner", value: "Senior Partner" },
  { label: "Associate", value: "Associate" },
  { label: "Senior Associate", value: "Senior Associate" },
  { label: "Director", value: "Director" },
  { label: "Senior Director", value: "Senior Director" },
];
export const relationList = [
  { label: "Father", value: "Father" },
  { label: "Mother", value: "Mother" },
  { label: "Relative", value: "Relative" },
  { label: "Others", value: "Others" },
];

export const timelap = [
  { label: "am", value: "am" },
  { label: "pm", value: "pm" },
];

export const workType = [
  { label: "Full Day", value: "Full Day" },
  { label: "Half Day", value: "Half Day" },
  { label: "Rest Day", value: "Rest Day" },
];

export const availabilityType = [
  { label: "Available", value: "Available" },
  { label: "Unavailable", value: "Unavailable" },
];

export const alternateSat = [
  {
    label: "A (Week 1 and 3 of every month)",
    value: "A (Week 1 and 3 of every month)",
  },
  {
    label: "B (Week 2 and 4 of every month)",
    value: "B (Week 2 and 4 of every month)",
  },
];

export const pStatus = [
  { label: "Expiring", value: "Expiring" },
  { label: "Expired", value: "Expired" },
  // { label: 'Active', value: 'Active' },
];

export const bloodList = [
  { label: "A+", value: "A+" },
  { label: "A-", value: "A-" },
  { label: "B+", value: "B+" },
  { label: "B-", value: "B-" },
  { label: "O-", value: "O-" },
  { label: "O+", value: "O+" },
  { label: "AB+", value: "AB+" },
  { label: "AB-", value: "AB-" },
];

// export const socket = socketIOClient('https://devcomm.limkokwing.net/', {
//   extraHeaders: {
//     authorization: token,
//     user_id: id,
//     user_type: 'lecturer',
//   },
// });

export const socket = {};
